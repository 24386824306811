<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h4 class="step__heading">{{ $t("Enrich your rental passport") }}</h4>
          <p class="fw-medium text-gray">{{$t('nordigen_step_1_detail')}}</p>


          <ul class="p-0 mb-4 fw-bold">
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{$t('nordige_step1_point1')}}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{$t('nordige_step1_point2')}}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{$t('nordige_step1_point3')}}
            </li>
          </ul>

          <span class="py-4" style="color:#016FFF">
            {{$t('nordigen_step1_tip')}}
          </span>
          <div class="w-100 mt-4">
            <button class="btn btn-primary btn-block" @click="$router.push({ name:'NordigenStep2' })">
              {{$t('Next step')}}
            </button>
            <button class="btn btn-white btn-block mt-4">{{$t('Complete this later')}}</button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <img src="../../../assets/images/nordigen/step1.png" alt="">
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>
  
<script>
import { mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import ExclamationCircle from "../../../components/svg/ExclamationCircle.vue";
export default {
  data() {
    return {
      step: null
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    ExclamationCircle
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  created() {
    this.step = +this.$route.params.step;
  },
  methods: {
  },
};
</script>
  
<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
  